.wrapper {  
    font-family: 'Helvetica';
    font-size: 14px;
  }

.step-progess-item {
  position: relative;
  counter-increment: list;
  
  &:not(:last-child) {
    padding-bottom: 20px;
  }
  
  &::before {
    display: inline-block;
    content: '';
    position: absolute;
    left: -20px;
    height: 100%;
    width: 10px;
  }
  
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: -26px;
    width: 10px;
    height: 10px;
    border: 2px solid #CCC;
    border-radius: 50%;
    background-color: #FFF;
  }
}

.last-progress {
    position: relative;
    counter-increment: list;
    
    &:not(:last-child) {
      padding-bottom: 20px;
    }
    
    /* &::before {
      display: inline-block;
      content: '';
      position: absolute;
      left: -30px;
      height: 100%;
      width: 10px;
    } */
    
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0px;
      left: -26px;
      width: 10px;
      height: 10px;
      border: 2px solid #CCC;
      border-radius: 50%;
      background-color: #FFF;
    }
}   

.is-done {
  &::before {
    border-left: 2px solid  rgb(79, 163, 144);
  }
  &::after {
    content: "✔";
    font-size: 9px;
    color: #FFF;
    text-align: center;
    border: 2px solid rgb(79, 163, 144);
    background-color: rgb(79, 163, 144);
  }
}

.not-done {
  &::before {
    border-left: 2px solid #CCC;
  }
}

.current {
  &::before {
    border-left: 2px solid rgb(79, 163, 144);
  }
  
  &::after {
    content: counter(list);
    padding-top: 1px;
    width: 18px;
    height: 16px;
    top: -4px;
    left: -30px;
    font-size: 14px;
    text-align: center;
    color: rgb(79, 163, 144);
    border: 2px solid rgb(79, 163, 144);
    background-color: white;
  }
}

.step-progess {
    position: relative;
    margin-left: 60px;
    list-style: none;
    
    /* &::before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        left: 15px;
        width: 10px;
        height: 100%;
        border-left: 2px solid #CCC;
    }

    strong {
        display: block;
    } */
}

.timestamp{
  color: #8D8D8D;
  position: absolute;
  width:100px;
  left: -138px;
  text-align: right;
  font-size: 12px;
}